import { makeStyles } from '@bequestinc/wui';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles()({
  root: {
    width: '50%',
    margin: '50px auto',
  },
  skeletonForm: {
    height: 125,
  },
});

const Placeholder = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {[1, 2, 3, 4, 5].map(n => (
        <Skeleton key={n} animation="wave" className={classes.skeletonForm} />
      ))}
    </div>
  );
};

export default Placeholder;
