import { observer } from 'mobx-react';

import { useCurrentUserQuery } from 'hooks/query-hooks/users/useUserQuery';

import { PANEL_MENU_ITEMS } from 'utils/constants';

import ButtonGrid from 'components/pages/home/ButtonGrid';

const PanelHomePage = () => {
  const { data: user } = useCurrentUserQuery();
  const buttonsToShow = PANEL_MENU_ITEMS.filter(
    item => !item.permissions || user.groups.some(i => item.permissions.includes(i)),
  );

  return (
    <ButtonGrid
      title="MLP Panel Department Homepage"
      bigButtons={buttonsToShow.filter(item => item.style === 'big')}
      smallButtons={buttonsToShow.filter(item => item.style === 'small')}
    />
  );
};

export default observer(PanelHomePage);
