import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

import { Spacer, makeStyles } from '@bequestinc/wui';
import { Button, Grid, Typography } from '@mui/material';

import ButtonLink from 'components/ButtonLink';
import TooltipField from 'components/custom_fields/TooltipField';

// TODO move some button stuff into global css
// TODO look into how the buttons can inherit from standard css defined here
const useStyles = makeStyles()(theme => ({
  root: {
    width: 850,
    margin: 'auto',
  },
  bigButtons: {
    height: 200,
    width: 250,
    color: 'black',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey['300'],
    background: 'none',
    boxShadow: `0px 3px 5px ${theme.palette.grey['300']}`,

    '&:hover, &:focus': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  bigText: {
    fontSize: 20,
    color: 'black',
  },
  smallButtons: {
    height: 50,
    width: 200,
    color: 'black',
    border: '1px solid #cbcfd4',
    background: 'none',
    boxShadow: 'none',
    marginRight: 16,
    marginLeft: 16,

    '&:hover, &:focus': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  smallText: {
    fontSize: 16,
    color: 'black',
  },
  text: {
    textAlign: 'center',
    fontWeight: 'normal',
  },
}));

const ButtonGrid = ({ title, bigButtons, smallButtons }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Spacer v={56} />
      <Typography variant="h1" className={classes.text}>
        {title}
      </Typography>
      <Spacer v={8} />
      <Typography variant="h6" className={classes.text}>
        What can we help you with today?
      </Typography>
      <Spacer v={48} />

      <Grid container direction="row" justifyContent="center" spacing={5}>
        {bigButtons.map(({ description, href, tooltipText = '' }) => (
          <Grid item xs={4} key={description}>
            <TooltipField title={tooltipText}>
              <Button
                component={ButtonLink}
                className={classes.bigButtons}
                href={href}
                variant="contained"
              >
                <Typography align="center" className={classes.bigText}>
                  {description}
                </Typography>
              </Button>
            </TooltipField>
          </Grid>
        ))}
      </Grid>

      <Spacer v={40} />

      <Grid container direction="row" justifyContent="center">
        {smallButtons.map(({ component, description, href, tooltipText = '' }) => (
          <Grid item key={description}>
            <TooltipField title={tooltipText}>
              <Button
                component={component}
                className={classes.smallButtons}
                href={href}
                variant="contained"
              >
                <Typography align="center" className={classes.smallText}>
                  {description}
                </Typography>
              </Button>
            </TooltipField>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

ButtonGrid.propTypes = {
  title: PropTypes.string.isRequired,
  bigButtons: PropTypes.array.isRequired,
  smallButtons: PropTypes.array.isRequired,
};

export default observer(ButtonGrid);
