import { observer } from 'mobx-react';
import { useState } from 'react';

import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { makeStyles } from '@bequestinc/wui';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Button, CircularProgress, Grid, IconButton, Typography } from '@mui/material';

import parseErrors from 'utils/errors';

const useStyles = makeStyles()(theme => ({
  text: {
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}));

const UploadedDocuments = ({
  uploadedDocuments,
  setUploadedDocuments,
  allowDownload,
  includeLabel,
  getDocument,
  deleteDocument,
}) => {
  const { classes } = useStyles();
  const [documentViewerOpen, setDocumentViewerOpen] = useState(false);
  const [documentToView, setDocumentToView] = useState({ filename: '', url: '', isBlob: false });
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState('');

  const openDocumentViewer = (doc, index) => {
    if (doc.notUploaded) {
      setDocumentToView({
        url: URL.createObjectURL(doc.file),
        filename: doc.filename,
        isBlob: true,
      });
      setDocumentViewerOpen(true);
    } else {
      setLoading(index);
      getDocument(doc.id)
        .then(response => {
          setDocumentToView({ url: response.data, filename: doc.filename });
          setDocumentViewerOpen(true);
          setError('');
        })
        .catch(e => {
          if (e.response.status === 400) {
            setError(parseErrors(e.response));
          } else {
            setError('Error fetching document! Please try again');
          }
        })
        .finally(() => setLoading(null));
    }
  };

  const DocumentViewer = dynamic(() => import('./DocumentViewer'), { ssr: false });

  const onRemove = (doc, idx) => {
    const removeFromList = () => {
      setUploadedDocuments([
        ...uploadedDocuments.slice(0, idx),
        ...uploadedDocuments.slice(idx + 1),
      ]);
    };
    if (doc.notUploaded) {
      removeFromList();
    } else {
      deleteDocument(doc.id).then(() => {
        removeFromList();
      });
    }
  };

  const downloadDoc = doc => {
    const link = document.createElement('a');
    getDocument(doc.id)
      .then(response => {
        link.href = URL.createObjectURL(response.data);
      })
      .then(() => {
        link.download = doc.filename;
        link.click();
      })
      .catch(() => setError('Error downloading document! Please try again'));
  };

  return (
    <>
      <Grid container direction="column" alignItems="flex-start">
        {includeLabel && (
          <Grid item>
            <Typography>
              <b>Documents:</b>
            </Typography>
          </Grid>
        )}
        {uploadedDocuments.map((doc, index) => (
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            key={index}
            alignItems="center"
          >
            {loading === index && <CircularProgress size="1rem" />}
            {deleteDocument && (
              <Grid item>
                <IconButton
                  onClick={() => onRemove(doc, index)}
                  size="large"
                  disabled={loading != null}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
            )}
            <Grid item>
              <Button onClick={() => openDocumentViewer(doc, index)} disabled={loading != null}>
                <Typography className={classes.text}>{doc.filename}</Typography>
              </Button>
              {allowDownload && (
                <Button onClick={() => downloadDoc(doc)} disabled={loading != null}>
                  <DownloadForOfflineIcon />
                </Button>
              )}
            </Grid>
          </Grid>
        ))}
        {error && (
          <Grid item>
            <Typography color="error" fontWeight="bold">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>

      <DocumentViewer
        document={documentToView}
        open={documentViewerOpen}
        onClose={() => setDocumentViewerOpen(false)}
      />
    </>
  );
};

UploadedDocuments.propTypes = {
  uploadedDocuments: PropTypes.arrayOf(PropTypes.object).isRequired,
  setUploadedDocuments: PropTypes.func,
  allowDownload: PropTypes.bool,
  includeLabel: PropTypes.bool,
  getDocument: PropTypes.func,
  deleteDocument: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

UploadedDocuments.defaultProps = {
  includeLabel: true,
  setUploadedDocuments: () => {},
  allowDownload: true,
  getDocument: () => {},
  deleteDocument: null,
};

export default observer(UploadedDocuments);
