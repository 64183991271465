import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

import { useCurrentUserQuery } from 'hooks/query-hooks/users/useUserQuery';

const DAYS = 60;

const TooltipField = ({ title, children, ...props }) => {
  const { data: user } = useCurrentUserQuery();

  return (
    <Tooltip {...props} title={user.daysActive < DAYS ? title : ''}>
      {children}
    </Tooltip>
  );
};

TooltipField.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default observer(TooltipField);
