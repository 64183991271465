import { observer } from 'mobx-react';

import { useRouter } from 'next/router';

import { useGlobalContext } from 'hooks';
import { useCurrentUserQuery } from 'hooks/query-hooks/users/useUserQuery';

import Page from 'components/Page';
import AttorneyHome from 'components/pages/home/AttorneyHome';
import PanelHome from 'components/pages/home/PanelHome';
import PlaceHolder from 'components/pages/home/PlaceHolder';

const HomePage = () => {
  const { firm } = useGlobalContext();
  const router = useRouter();

  const { data: user, isFetching } = useCurrentUserQuery();

  if (isFetching || (!user.isPanelUser && user.firm && firm.loading)) {
    return <PlaceHolder />;
  }

  // The user is not a registered panel rep or panel user and they are not associated with a firm,
  // Use their existing attyCode & ein to do a DB2 lookup to link them to their existing firm.
  if (!isFetching && !user.isPanelUser && !user.firm) {
    router.replace('/welcome');
    return null;
  }

  if (!isFetching && user.isAttorneyUser && !user.hasRequiredProfile) {
    router.replace('/create-attorney-profile');
    return null;
  }

  return (
    <Page title="Home" showBackButton={false}>
      {user.isPanelUser ? <PanelHome /> : <AttorneyHome />}
    </Page>
  );
};

export default observer(HomePage);
