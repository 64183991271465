import { observer } from 'mobx-react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { createMalpracticeDocument } from 'api';
import PropTypes from 'prop-types';

import { Modal, makeStyles } from '@bequestinc/wui';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import parseErrors from 'utils/errors';

import UploadedDocuments from 'components/UploadedDocuments';

const useStyles = makeStyles()({
  root: {
    width: '80%',
    maxWidth: '1000px',
    margin: 'auto',
  },
  container: {
    width: '90%',
    margin: 'auto',
    padding: '30px',
  },
  dropZone: {
    padding: '50px',
    height: '300px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    transition: 'border .24s ease-in-out',
  },
  icon: {
    fill: '#eeeeee',
    width: 150,
    height: 150,
    display: 'block',
    margin: 'auto',
  },
  button: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 20,
  },
});

const MalpracticeUploadModal = ({ open, onClose, afterUploadAction, firmId }) => {
  const { classes } = useStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(acceptedFiles => {
    setSelectedFiles([
      ...acceptedFiles.map(file => ({ filename: file.name, file, notUploaded: true })),
    ]);
    setShowSuccessMessage(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf',
    multiple: false,
  });

  const closeModal = () => {
    selectedFiles.forEach(file => {
      file.firm = firmId;
    });
    const formData = new FormData();

    Object.entries(selectedFiles[0]).forEach(entry => {
      formData.append(entry[0], entry[1]);
    });

    setLoading(true);

    createMalpracticeDocument(formData)
      .then(() => {
        setSelectedFiles([]);
        afterUploadAction();
        setErrorMessage('');
        setShowSuccessMessage(true);
      })
      .catch(e => setErrorMessage(parseErrors(e.response)))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classes.root}>
      <Modal title="Upload Documents" open={open} onClose={onClose}>
        <section className={classes.container}>
          <div {...getRootProps()} className={classes.dropZone}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography align="center" className={classes.text}>
                Drop the files here...
              </Typography>
            ) : (
              <Typography align="center">
                Drag and drop a file here or click to select a file.
                <br />
                (Only .pdf files will be accepted)
              </Typography>
            )}
            <PermMediaIcon className={classes.icon} />
          </div>
        </section>
        <UploadedDocuments
          uploadedDocuments={selectedFiles}
          setUploadedDocuments={setSelectedFiles}
          allowDownload={false}
        />
        <LoadingButton
          disabled={selectedFiles.length === 0}
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={closeModal}
          loading={loading}
        >
          Submit Proof of Malpractice
        </LoadingButton>
        {errorMessage && (
          <Typography fontWeight="bold" color="error" style={{ whiteSpace: 'pre-line' }}>
            {errorMessage}
          </Typography>
        )}
        {showSuccessMessage && (
          <Typography fontWeight="bold" color="primary">
            Upload succeeded! You may close this window.
          </Typography>
        )}
      </Modal>
    </div>
  );
};

MalpracticeUploadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  afterUploadAction: PropTypes.func,
  firmId: PropTypes.number.isRequired,
};

MalpracticeUploadModal.defaultProps = {
  afterUploadAction: () => {},
};

export default observer(MalpracticeUploadModal);
