import { observer } from 'mobx-react';

import Link from 'next/link';
import PropTypes from 'prop-types';

const ButtonLink = React.forwardRef(({ className, href, as, children, ...props }, ref) => (
  <Link href={href} as={as || href}>
    <a ref={ref} className={className} {...props}>
      {children}
    </a>
  </Link>
));
ButtonLink.displayName = 'ButtonLink';

ButtonLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ButtonLink.defaultProps = {
  className: '',
  as: '',
};

export default observer(ButtonLink);
