const convertVariableName = varName => {
  const result = varName.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const searchItem = (item, parentObj, result) => {
  Object.keys(item).forEach(key => {
    if (typeof item[key] === 'string') {
      const varName = convertVariableName(parentObj);
      result.push(` ${varName}: ${item.join('\n')}`);
    }
    // If array, join the elements (strings) together
    else if (Array.isArray(item[key])) {
      const varName = convertVariableName(key);
      result.push(` ${varName}: ${item[key].join('\n')}`);
    }
    // If another object, call searchItem again
    else {
      searchItem(item[key], null, result);
    }
  });
};

const parseErrors = (
  errorResponse,
  genericError = 'An unknown error occurred! Try refreshing the page.',
) => {
  if (typeof errorResponse === 'undefined' || [500, 404].includes(errorResponse.status)) {
    // 500 errors are a backend error and the response doesn't contain good error info.
    // Just show the generic error.
    return genericError;
  }
  if ([503, 504].includes(errorResponse.status)) {
    return 'Request failed, please wait and try again.';
  }
  if (errorResponse.status === 403) {
    return 'You do not have permission to perform this action.';
  }
  if (
    typeof errorResponse.data === 'string' &&
    errorResponse.status >= 400 &&
    errorResponse.status < 500
  ) {
    // The backend sent back the error directly in the string.
    return errorResponse.data;
  }
  if (Array.isArray(errorResponse.data)) {
    return errorResponse.data.join(', ');
  }
  // error occurs if the errorResponse.data only contains one object. This will catch it.
  if (typeof errorResponse.data === 'object' && errorResponse.data.detail) {
    return errorResponse.data.detail;
  }
  if (typeof errorResponse.data === 'object' && !Array.isArray(errorResponse.data)) {
    const result = [];
    Object.keys(errorResponse.data).forEach(key => {
      if (typeof errorResponse.data[key] === 'object') {
        searchItem(errorResponse.data[key], key, result);
      }
    });
    return `Encountered the following errors:\n${result.join('\n')}`;
  }
  return genericError;
};

export default parseErrors;
